import { decode } from 'html-entities'
import { kebabCase } from 'lodash-es'
import { useStorage } from '@vueuse/core'
import { mapperIcon } from '../../core/data-layer/utils/mappers/index'
import { greenhouseMappers } from '../data-layer/mappers'

const GREENHOUSE_URL = 'https://boards-api.greenhouse.io/v1/boards/adyen'
const NOT_SET = -1
const TEST_LIMIT = 5

type JobsData = {
  jobs: any[];
  departments: any[];
  offices: any[];
  jobPositions: any[];
};

export const useGreenHouse = () => {
  const { filterRelatedJobs } = useGreenHouseUtils()
  const jobsData = useStorage<JobsData | {}>('greenhouse-jobs', {})

  const EMPTY_TEAM = greenhouseMappers.getTeamForGreenhouseId(NOT_SET)
  const EMPTY_OFFICE = greenhouseMappers.getOfficeForGreenhouseId(NOT_SET)

  // https://developers.greenhouse.io/job-board.html#list-offices
  const fetchOffices = async () => {
    const { offices } = await $fetch<{ offices: any[] }>(
      `${GREENHOUSE_URL}/offices`
    )
    return (
      offices
        // Only one final offices, not regions
        .filter(office => office.child_ids.length === 0 && office.id !== 0)
        .map(office => ({
          id: office.id,
          name: office.name,
          icon: greenhouseMappers.getOfficeForGreenhouseId(
            office.id,
            office.name
          )?.logo,
          location: office.location,
          departments: office.departments.map(dep => dep.id)
        }))
    )
  }

  const fetchDepartments = async () => {
    const { departments } = await $fetch<{ departments: any[] }>(
      `${GREENHOUSE_URL}/departments`
    )
    return (
      departments
        // Only one final departments, not meta departments
        .filter(
          department =>
            department.child_ids.length === 0 && department.id !== 0
        )
        .map(department => ({
          id: department.id,
          name: department.name,
          icon: greenhouseMappers.getTeamForGreenhouseId(
            department.id,
            department.name
          )?.logo,
          jobs: department.jobs.map(dep => dep.id)
        }))
    )
  }

  // https://developers.greenhouse.io/job-board.html#list-jobs
  const fetchJobs = async (skipContent = false) => {
    const content = skipContent ? '' : 'content=true'
    const { jobs } = await $fetch<{ jobs: any[] }>(
      `${GREENHOUSE_URL}/jobs?${content}`
    )
    return jobs.map((job) => {
      const departments =
        job.departments?.length > 0
          ? job.departments.map(department => ({
            id: department.id,
            name: department.name,
            icon: mapperIcon(
              greenhouseMappers.getTeamForGreenhouseId(
                department.id,
                department.name
              )?.logo
            )
          }))
          : [
              {
                ...EMPTY_TEAM,
                icon: mapperIcon(EMPTY_TEAM.logo)
              }
            ]

      const offices =
        job.offices?.length > 0
          ? job.offices.map(office => ({
            id: office.id,
            name: office.name,
            icon: mapperIcon(
              greenhouseMappers.getOfficeForGreenhouseId(
                office.id,
                office.name
              )?.logo
            )
          }))
          : [
              {
                ...EMPTY_OFFICE,
                icon: mapperIcon(EMPTY_OFFICE.logo)
              }
            ]

      return {
        id: job.id,
        name: job.title,
        slug: `${job.id}-${kebabCase(job.title)}`,
        location: job.location?.name,
        departments,
        departmentsLabel: departments.map(d => d.name).join(', '),
        offices,
        officesLabel: offices.map(o => o.name).join(', '),
        title: job.title,
        // Needs to decode content from greenhouse
        content: decode(job.content),
        url: job.absolute_url,
        timestamp: new Date(job.updated_at).getTime()
      }
    })
  }

  const fetchAllGreenhouseData = async () => {
    const [jobs, departments, offices] = await Promise.all([
      fetchJobs(),
      fetchDepartments(),
      fetchOffices()
    ])
    const jobPositions = [...new Set(jobs.map(j => j.name))].map(
      position => ({
        id: position,
        name: position
      })
    )

    const data = {
      jobs,
      departments,
      offices,
      jobPositions
    }

    jobsData.value = data
    return data
  }

  const getGreenhouseJobsData = async ({
    sortByLatest = false,
    withLimit = false
  } = {}) => {
    if (!jobsData.value?.jobs) {
      await fetchAllGreenhouseData()
    }

    const { jobs } = jobsData.value!
    const LIMIT = withLimit ? TEST_LIMIT : null

    const items = sortByLatest
      ? jobs.sort((a, b) => b.timestamp - a.timestamp)
      : jobs

    return {
      items: LIMIT ? items.slice(0, LIMIT) : items,
      totalAmount: items.length
    }
  }

  const getGreenhouseJobsForCity = async (
    city,
    { sortByLatest = true, withLimit = true }
  ) => {
    const { items } = await getGreenhouseJobsData({ sortByLatest })
    const office = greenhouseMappers.getOfficeForCity(city)
    if (!office) {
      return {
        items: [],
        totalAmount: 0
      }
    }

    const filteredJobs = items.filter(j =>
      j.offices.some(of => of.id === office.id)
    )
    return {
      items: withLimit ? filteredJobs.slice(0, TEST_LIMIT) : filteredJobs,
      totalAmount: filteredJobs.length
    }
  }

  const getGreenhouseJobsForCareerType = async (
    careerType: any,
    { sortByLatest = true, withLimit = true }
  ) => {
    const { items } = await getGreenhouseJobsData({ sortByLatest })
    const teamsSet = new Set(
      greenhouseMappers.getTeamsForCareerType(careerType)?.map(t => t.id) ||
        []
    )
    const filteredJobs = items.filter(j =>
      j.departments.some(dep => teamsSet.has(dep.id))
    )
    return {
      items: withLimit ? filteredJobs.slice(0, TEST_LIMIT) : filteredJobs,
      totalAmount: filteredJobs.length
    }
  }

  const getGreenhouseJob = async (jobId: number) => {
    if (Number.isNaN(jobId)) {
      return null
    }

    if (!jobsData.value?.jobs) {
      await fetchAllGreenhouseData()
    }

    let job = jobs.value.find(j => j.id === jobId)

    if (!job) {
      await fetchAllGreenhouseData()
      await nextTick()
      job = jobs.value.find(j => j.id === jobId)
    }

    if (!job) {
      return null
    }

    const relatedJobs = filterRelatedJobs(job, jobs.value)
    return {
      job,
      relatedJobs
    }
  }

  const jobs = computed(() => jobsData.value?.jobs || [])

  const JobFilter = {
    LOCATION: {
      name: 'location',
      query: 'location',
      ghParam: 'offices'
    },
    TEAM: {
      name: 'team',
      query: 'team',
      ghParam: 'departments'
    }
  }

  const getFilterForFilterName = (filterName: string) => {
    return filterName === 'location' ? JobFilter.LOCATION : JobFilter.TEAM
  }

  return {
    fetchAllGreenhouseData,
    getGreenhouseJob,
    getGreenhouseJobsData,
    getGreenhouseJobsForCity,
    getGreenhouseJobsForCareerType,
    getFilterForFilterName,
    fetchJobs,
    JobFilter,
    jobsData,
    jobs,
    greenhouseMappers
  }
}
