import { greenhouseMappers } from '../data-layer/mappers'
import { mapperIcon } from '../../core/data-layer/utils/mappers/index.js'

const JobFilter = {
  LOCATION: {
    name: 'location',
    query: 'location',
    ghParam: 'offices'
  },
  TEAM: {
    name: 'team',
    query: 'team',
    ghParam: 'departments'
  }
}

export const useGreenHouseUtils = () => {
  const filterRelatedJobs = (job, jobs) => {
    const departments = job.departments.map(dep => dep.id)
    const departmentsSet = new Set(departments)
    let relatedJobs = jobs.filter(j => j.departments.some(dep => departmentsSet.has(dep.id)))

    if (relatedJobs.length < 3) {
      const relatedTeamsSet = new Set(greenhouseMappers.getRelatedTeams(departments[0]).map(t => t.id))
      const extraRelatedJobs = jobs.filter(j => j.departments.some(dep => relatedTeamsSet.has(dep.id)))
      relatedJobs = relatedJobs.concat(extraRelatedJobs)
    }
    return relatedJobs.slice(0, 3)
  }

  const getQueryParamsForJob = (job) => {
    const departmentId = job.departments[0].name
    return `${JobFilter.TEAM.query}=${departmentId}`
  }
  const getQueryParamsForCity = (city) => {
    const office = greenhouseMappers.getOfficeForCity(city)
    return `&${JobFilter.LOCATION.query}=${office.name}`
  }

  const getQueryParamsForCareerType = (careerType) => {
    const teams = greenhouseMappers.getTeamsForCareerType(careerType)
    return teams.map(team => `&${JobFilter.TEAM.query}=${team.name}`)
  }

  const getFilterForFilterName = (filterName) => {
    return filterName === 'location' ? JobFilter.LOCATION : JobFilter.TEAM
  }

  const getIconForCity = (cityId: string) => {
    const office = greenhouseMappers.getOfficeForCity(cityId)
    return mapperIcon(office?.logo)
  }

  return {
    JobFilter,
    getQueryParamsForJob,
    filterRelatedJobs,
    getQueryParamsForCity,
    getQueryParamsForCareerType,
    getFilterForFilterName,
    getIconForCity
  }
}
